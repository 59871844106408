<template>
    <div>
      <h1>Welcome</h1>
      <h4 style="font-weight: normal;">
        Hello, this is a mobile website and app builder for hotels, campsites
        and apartments. <br />Create a new experience for your guests with
        guest.mobi
      </h4>
    </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import sound from "@/assets/audio.mp3";

export default {
  components:{
  },
  data() {
    return {
      titleName: "",
      object: "",
      mute:false,
      orderList: [],
      selectedOrder: null,
      headers: [
        { text: "Avatar", align: "center", sortable: true },
        { text: "Name", align: "center", sortable: true },
        { text: "Date", align: "center", sortable: true },
        { text: "Time", align: "center", sortable: true },
        { text: "Status", align: "center", sortable: true },
      ],
      headersHistory: [
        { text: "Avatar", align: "center", sortable: true },
        { text: "Name", align: "center", sortable: true },
        { text: "Date", align: "center", sortable: true },
        { text: "Time", align: "center", sortable: true },
        { text: "Status", align: "center", sortable: true },
      ],
      deleteOrderId:null,
      order_status_types: [
        {
          text: "New",
          value: "new",
        },
        {
          text: "In processing",
          value: "is_processing",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Deleted",
          value: "deleted",
        },
        {
          text: "Canceled",
          value: "canceled",
        },
      ],
      order_pay_types: [
        {
          text: "Not paid",
          value: "not paid",
        },
        {
          text: "Paid",
          value: "paid",
        },
      ],
    };
  },
  async mounted() {


    if (!this.$store.state.profile.name) {
      await this.$store.dispatch("getProfile");
    }
      this.titleName = "Welcome";
    document.title = this.titleName;

  },
  name: "Welcome.vue",
  computed: {
    ...mapState(["objects"]),
    ...mapState(['landing']),
    VUE_APP_STORAGE_URL(){
      return process.env.VUE_APP_STORAGE_URL
    },
    orderNew() {
      return this.orderList.filter((x) => x.order_status === "new");
    },
    orderProcess() {
      return this.orderList.filter((x) => x.order_status === "is_processing");
    },
    orderComplete() {
      return this.orderList.filter((x) => x.order_status === "complete");
    },
  },

  methods: {
    toggleMute(){
        this.mute = !this.mute;
    },
    changeStatus(status){
      const candidate = this.order_status_types.find(x => status === x.value);
      return candidate ? candidate.text : '';
    },
    totalOrder(arr){
      let total = 0;
      let currency = "";
      for(let i = 0;i<arr.length;i++){
        for(let index = 0;index<arr[i].length;index++){
          let item = arr[i][index];
          if(item.radio === 'text'){
            total += +item.price;
            currency = item.currency;
          }else if (item.radio === 'number'){
            total+= +item.price*+item.count;
            currency = item.currency;
          }else if(item.radio === 'checkbox'){
            total += +item.price;
            currency = item.currency;
          }else if(item.radio === 'radio'){
            total += +item.price;
            currency = item.currency;
          }
        }
      }
      return total.toFixed(2) + " ";
    },
    async deleteOrderConfirm(){
        await this.$axios.delete( `${process.env.VUE_APP_API_URL}order/${this.selectedOrder.id}`);
        this.orderList.splice()
    },
    summOfPrices(arr){
      let total = 0;
      for(let i = 0;i<arr.length;i++){
        for(let index = 0;index<arr[i].length;index++){
                let item = arr[i][index];
              if(item.radio === 'text'){
                  total+=+item.price;
              }else if (item.radio === 'number'){
                  total+=+item.price*+item.count;
              }else if(item.radio === 'checkbox'){
                  total+=+item.price;
              }
        }
      }
      return total.toFixed(2);
    },
    async saveChanges(item) {
        const {data} = await this.$axios.put(
          `${process.env.VUE_APP_API_URL}order/${item.id}`,
          item
        );
        let candidate = this.orderList.find((x) => x.id === item.id)
        this.orderList[this.orderList.indexOf(candidate)] =  data;
        this.$refs.PopupSuccess.open();
    },
    formatDateDetail(date) {
      return moment(date).format("DD.MM.yyyy");
    },
    formatTimeDetail(date){
      return moment(date).format('HH:mm')
    },
    selectOrder(order) {
      this.selectedOrder = JSON.parse(JSON.stringify(order));
    },
    orderStatusColor(status) {
      if (status === "new") {
        return "rgb(255,244,197)";
      } else if (status === "is_processing") {
        return "rgb(12,127,23)";
      } else {
        return "rgb(252,13,151)";
      }
    },
    changeFormatDate(date) {
      return moment(date).fromNow();
    },
      async changeObject(id) {
      await this.$router.push({
        name: "Constructor",
        params: { id: id, lang: this.$route.params.lang },
      });
      this.getLanding(id);
    },
  },
};
</script>

<style lang="scss">
.leftSidebar {
  display: flex;
  flex-direction: column;

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    &Title {
      font-weight: 700;
      font-size: 20px;
    }
  }
}
.leftSidebar .v-tab {
  border-radius: 15px;
}
.leftSidbarBodyTabItem {
  max-height: 900px;
  overflow-y: auto;
}
.leftSidebar .v-slide-group__prev {
  display: none;
}
.leftSidebar .v-slide-group__next {
  display: none;
}
.leftSidebar .v-tab.v-tab--active {
  color: #fff;
  background: rgb(58, 92, 202);
}
.leftSidebar .v-tabs-bar {
  margin-bottom: 15px;
}
.phone {
  background: rgb(17, 127, 23);
  cursor: pointer;
}
.phone:hover {
  background-color: rgb(17, 175, 23);
}

.tariff_obj {
  margin-left:5px;
  display: inline-block;
  padding: 4px 6px;
  text-transform: uppercase;
  border-radius: 0 5px 0 5px;
  background: #007db5;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: #fff;
}
</style>
